<template lang="html">

  <section class="new-small" v-if="dataContent">
      <router-link :to="{
        name : 'viewNewSmall',
        params: {
        cat: dataContent.category.slug,
        slug:dataContent.slug,
      }}" class="box-entry -small" :data-bg="dataContent.category.slug"  :style="`background-image:url(/img/bg/bg-` + dataContent.category.slug + `.jpg)`">
        
        <section class="content-info">
          <p class="title w-100">{{dataContent.category.name}}</p>
          <div class="img-round">
            
            <img :src="dataContent.image.url" :alt="dataContent.name">
          </div>
          <div class="text-info">
            <p class="description">{{dataContent.name}}
            </p>
          </div>
        </section>
      </router-link>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'new-small',
    props: ['dataContent'],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .new-small {

  }
</style>
