<template lang="html">
  <section class="entries-page">
    <bannerHead data-title="Comunidad UFV"></bannerHead>
    <breacrumbs data-title="Comunidad UFV"></breacrumbs>
    <div class="container">
      <div class="grid-3 news-light" v-if="Object.keys(store.lightEntries).length != 0">
        <template v-for="(ligthEntry, key ) in getLightEntryByCategoryBySLug('bienvenida').slice(0, 1)" :key="key">
          <div class="grid">
            <newSmall :dataContent="ligthEntry"></newSmall>
          </div>
        </template>
        <template v-for="(ligthEntry, key ) in getLightEntryByCategoryBySLug('movilidad').slice(0, 1)" :key="key">
          <div class="grid">
            <newSmall :dataContent="ligthEntry"></newSmall>
          </div>
        </template>
        <template v-for="(ligthEntry, key ) in getLightEntryByCategoryBySLug('seguimos-creciendo').slice(0, 1)"
          :key="key">
          <div class="grid">
            <newSmall :dataContent="ligthEntry"></newSmall>
          </div>
        </template>
      </div>
      <div class="box-filters">
        <p class="title-line">Estructura</p>
        <div style="width: 70%; margin:20px auto"><div style="position: relative; padding-bottom: 56.25%; padding-top: 0; height: 0;"><iframe title="Estructura organizativa" frameborder="0" width="1200px" height="675px" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" src="https://view.genial.ly/63ececa97d04f6001860c55a" type="text/html" allowscriptaccess="always" allowfullscreen="true" scrolling="yes" allownetworking="all"></iframe> </div> </div>
      </div>


    </div>

  </section>
</template>

<script lang="js">
import bannerHead from '@/components/banner-head.vue'
import breacrumbs from '@/components/breacrumbs.vue'
import newSmall from '@/components/new-small.vue'
import { contentStore } from '@/stores/contents'
const store = contentStore();
export default {
  name: 'entries-page',
  props: [],
  components: {
    bannerHead,
    breacrumbs,
    newSmall,
  },
  setup() {
    return {
      store,
      getLightEntryByCategoryBySLug: store.getLightEntryByCategoryBySLug,
      setPageEntries: store.setPageEntries,
      getEntriesPage: store.getEntriesPage
    }
  },
  mounted() {
    this.categorySelected = store.entries.lastFilters.filter;
    this.textSearch = store.entries.lastFilters.text,
      store.loadLightEntries()
    store.loadEntries(
      {
        page: this.page,

      }
    )
    store.loadCategoriesEntries()

  },
  data() {
    return {
      textSearch: null,
      removeSearch: false,
      categorySelected: null,
    }
  },
  methods: {
    searchText() {
      store.loadEntries(
        {
          page: this.page,
          text: this.textSearch
        }
      )
      this.removeSearch = true

    },
    cleanText() {
      this.textSearch = null,
        this.removeSearch = false
      store.loadEntries(
        {
          page: this.page,
          text: this.textSearch
        }
      )

    },
    filterCategory() {
      store.loadEntries(
        {
          page: this.page,
          filter: this.categorySelected
        }
      )
    },
    changePages(item) {
      this.setPageEntries(item)
      store.loadEntries(
        {
          page: this.page,
        }
      )
    }
  },
  computed: {
    cleanTextTheme() {
      if (this.categorySelected != null) {
        return 'Eliminar selección'
      } else {
        return 'Ver todos'
      }
    },
    NewsMaxItems() {
      return store.getPagesEntries();
    },
    page() {
      return store.getEntriesPage
    }
  },
  watch: {
    'textSearch'() {
      if (this.textSearch == '') {
        store.loadEntries(
          {
            page: this.page,
          }
        )
      }
    }
  }

}


</script>

<style scoped lang="scss">
.entries-page {}
</style>
